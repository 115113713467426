import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"
import { filterOptionsBySearch, retailersToOptions } from "utils"
import { SearchInput } from "components/form-components/search-input/search-input"
import { Loader } from "components/loader/loader"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import { RetailerSelectorUncontrolled } from "components/select-retailer/retailer-selector-uncontrolled"
import { Radio } from "components/form-components/radio/radio"
import { Button } from "components/button/button"
import { RetailersFilterBy } from "common/enums/RetailersFilterBy.enum"
import { AuditType } from "common/enums/AuditType.enum"
import { Select, SelectOption } from "components/form-components/select/select"
import { retailersFilterByOptions } from "utils/constants"
import { useModal } from "context/modal-context"
import { SourceModal } from "components/source-modal/source-modal"

type Props = {
  disabled?: boolean
  selectedRetailers: RetailerSelectorOption[]
  setSelectedRetailers: React.Dispatch<React.SetStateAction<RetailerSelectorOption[]>>
  selectedUploadType: UploadType
  setSelectedUploadType: React.Dispatch<React.SetStateAction<UploadType>>
}

export enum UploadType {
  uploadMasterVersionContent = "0",
  uploadAsASource = "1",
}

export const SelectRetailerUncontrolled = ({
  disabled = false,
  selectedRetailers,
  setSelectedRetailers,
  selectedUploadType,
  setSelectedUploadType,
}: Props) => {
  const { setModal, unSetModal } = useModal()

  const [retailerOptions, setRetailerOptions] = useState<RetailerSelectorOption[]>([])
  const [frequentlyUsedOptions, setFrequentlyUsedOptions] = useState<
    RetailerSelectorOption[]
  >([])
  const [customRetailersOptions, setCustomRetailersOptions] = useState<
    RetailerSelectorOption[]
  >([])
  const [favoriteRetailersOptions, setFavoriteRetailersOptions] = useState<
    RetailerSelectorOption[]
  >([])
  const [searchQuery, setSearchQuery] = useState("")
  const [isScrolled, setIsScrolled] = useState(false)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filterBy, setFilterBy] = useState(RetailersFilterBy.ViewAll)

  const dispatch = useDispatch<RootDispatch>()
  const elementRef = useRef<HTMLDivElement>(null)
  const allRetailers = useSelector((state: RootState) => state.retailers.allRetailers)
  const frequentlyUsedRetailers = useSelector(
    (state: RootState) => state.retailers.frequentlyUsedRetailers,
  )
  const customRetailers = useSelector(
    (state: RootState) => state.retailers.customRetailers,
  )
  const favoriteRetailers = useSelector(
    (state: RootState) => state.retailers.favoriteRetailers,
  )
  const selectedAudit = useSelector((state: RootState) => state.audits.selectedAudit)
  const filteredFrequentlyUsed = filterOptionsBySearch(frequentlyUsedOptions, searchQuery)
  const filteredRetailerOptions = filterOptionsBySearch(retailerOptions, searchQuery)
  const filteredCustomRetailerOptions = filterOptionsBySearch(
    customRetailersOptions,
    searchQuery,
  )
  const filteredFavoriteRetailerOptions = filterOptionsBySearch(
    favoriteRetailersOptions,
    searchQuery,
  )

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value?.toLowerCase())
  }

  useEffect(() => {
    dispatch.retailers.fetchAllRetailers()
  }, [])

  useEffect(() => {
    const allRetailerOptions = retailersToOptions(allRetailers)
    const frequentlyUsedRetailerOptions = retailersToOptions(frequentlyUsedRetailers)
    const customRetailersOptions = retailersToOptions(customRetailers)
    const favoriteRetailersOptions = retailersToOptions(favoriteRetailers)

    switch (filterBy) {
      case RetailersFilterBy.FrequentlyUsed:
        setRetailerOptions(frequentlyUsedRetailerOptions)
        break
      case RetailersFilterBy.AllSites:
        setRetailerOptions(allRetailerOptions)
        break
      case RetailersFilterBy.Custom:
        setRetailerOptions(customRetailersOptions)
        break
      case RetailersFilterBy.Favorite:
        setRetailerOptions(favoriteRetailersOptions)
        break
      case RetailersFilterBy.ViewAll: {
        setIsLoading(true)
        const fetchData1 = dispatch.retailers.fetchAllRetailersByAuditType(
          selectedAudit?.auditType || AuditType.Product,
        )
        const fetchData2 = dispatch.retailers.fetchFrequentlyUsedRetailers(
          selectedAudit?.auditType || AuditType.Product,
        )
        const fetchData3 = dispatch.retailers.fetchCustomRetailers()
        const fetchData4 = dispatch.retailers.fetchFavoriteRetailers()
        Promise.all([fetchData1, fetchData2, fetchData3, fetchData4]).finally(() =>
          setIsLoading(false),
        )
        break
      }
    }
  }, [filterBy])

  useEffect(() => {
    setFrequentlyUsedOptions(retailersToOptions(frequentlyUsedRetailers))
    setCustomRetailersOptions(retailersToOptions(customRetailers))
    setFavoriteRetailersOptions(retailersToOptions(favoriteRetailers))
    setRetailerOptions(
      retailersToOptions(
        allRetailers.filter(
          (el) => !frequentlyUsedRetailers.find((option) => option.id === el.id),
        ),
      ),
    )
  }, [frequentlyUsedRetailers, allRetailers, customRetailers, favoriteRetailers])

  const onWheel = () => {
    const newIsScrolled = elementRef.current?.scrollTop !== 0
    if (newIsScrolled !== isScrolled) {
      setIsScrolled(newIsScrolled)
    }

    const newIsScrolledToBottom =
      elementRef.current?.scrollHeight !==
      (elementRef.current?.scrollTop ?? 0) + (elementRef.current?.offsetHeight ?? 0)

    if (newIsScrolledToBottom !== isScrolledToBottom) {
      setIsScrolledToBottom(newIsScrolledToBottom)
    }
  }

  return (
    <>
      <div className="flex flex-col w-full mb-3 pt-[30px]">
        <div className="flex justify-between h-10">
          <div className="flex gap-5">
            <Radio
              label="Upload Master Version Content"
              onChange={() => {
                setSelectedRetailers([])
                setSelectedUploadType(UploadType.uploadMasterVersionContent)
              }}
              checked={selectedUploadType === UploadType.uploadMasterVersionContent}
            />
            <Radio
              label="Upload as a Source"
              onChange={() => {
                setSelectedRetailers([])
                setSelectedUploadType(UploadType.uploadAsASource)
              }}
              checked={selectedUploadType === UploadType.uploadAsASource}
            />
          </div>
          {selectedUploadType === UploadType.uploadAsASource && (
            <Button
              variant="primary"
              onClick={() => {
                setModal(<SourceModal closeModal={unSetModal} />)
              }}
            >
              Add New Source
            </Button>
          )}
        </div>
        {selectedUploadType === UploadType.uploadMasterVersionContent ? (
          <span className="text-sub-headline font-semibold mb-[-10px] pt-7">
            Select one or more Retailers you'd like this data to apply to
          </span>
        ) : (
          <span className="text-sub-headline font-semibold mb-[-10px] pt-7">
            Select one Retailer you'd like this data to apply to
          </span>
        )}
        <div className="flex items-end relative gap-5">
          <div className="mt-[25px] grow">
            <SearchInput
              placeholder="Search"
              onChange={onSearch}
              className="w-full"
              height={48}
            />
          </div>
          <div style={{ width: 300 }}>
            <Select
              label="Filter by"
              options={retailersFilterByOptions}
              value={filterBy}
              onChange={(option: SelectOption) => {
                const newOption = retailersFilterByOptions.find(
                  (el) => el.value === option.value,
                )
                setFilterBy(newOption?.value as RetailersFilterBy)
                setSelectedRetailers([])
              }}
            />
          </div>
        </div>
      </div>
      <div className="relative" style={{ height: "calc(100% - 193px)" }}>
        {isScrolled && (
          <div
            className="absolute h-1 w-full top-0 bg-white z-10"
            style={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          />
        )}
        {isScrolledToBottom && (
          <div
            className="absolute bottom-0 w-full h-0.5"
            style={{
              boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
              borderRadius: "0px 0px 8px 8px",
            }}
          />
        )}
        <div
          className="h-full relative overflow-y-auto overflow-x-hidden py-5 w-full"
          ref={elementRef}
          onWheel={onWheel}
        >
          {isLoading ? (
            <Loader className="left-1/2 text-primary-" message="Loading..." />
          ) : (
            <>
              {filterBy === RetailersFilterBy.ViewAll && (
                <>
                  {filteredFrequentlyUsed.length > 0 && (
                    <>
                      <p className="text-callout font-semibold mb-3">Frequently used</p>
                      <RetailerSelectorUncontrolled
                        uploadType={selectedUploadType}
                        options={filteredFrequentlyUsed}
                        disabled={disabled}
                        selectedRetailers={selectedRetailers}
                        setSelectedRetailers={setSelectedRetailers}
                      />
                    </>
                  )}
                  {filteredRetailerOptions.length > 0 &&
                    frequentlyUsedOptions.length > 0 && (
                      <p className="text-callout font-semibold my-3">Others</p>
                    )}
                </>
              )}
              {((filterBy === RetailersFilterBy.ViewAll &&
                (filteredRetailerOptions.length > 0 ||
                  filteredFrequentlyUsed.length === 0)) ||
                filterBy !== RetailersFilterBy.ViewAll) && (
                <RetailerSelectorUncontrolled
                  uploadType={selectedUploadType}
                  options={filteredRetailerOptions}
                  disabled={disabled}
                  selectedRetailers={selectedRetailers}
                  setSelectedRetailers={setSelectedRetailers}
                />
              )}
              {filterBy === RetailersFilterBy.ViewAll &&
                filteredCustomRetailerOptions.length > 0 && (
                  <p className="text-callout font-semibold my-3">Custom</p>
                )}
              {filterBy === RetailersFilterBy.ViewAll &&
                filteredCustomRetailerOptions.length > 0 && (
                  <RetailerSelectorUncontrolled
                    uploadType={selectedUploadType}
                    options={filteredCustomRetailerOptions}
                    disabled={disabled}
                    isCustom
                    selectedRetailers={selectedRetailers}
                    setSelectedRetailers={setSelectedRetailers}
                  />
                )}
              {filterBy === RetailersFilterBy.ViewAll &&
                filteredFavoriteRetailerOptions.length > 0 && (
                  <p className="text-callout font-semibold my-3">Favorite</p>
                )}
              {filterBy === RetailersFilterBy.ViewAll &&
                filteredFavoriteRetailerOptions.length > 0 && (
                  <RetailerSelectorUncontrolled
                    uploadType={selectedUploadType}
                    options={filteredFavoriteRetailerOptions}
                    disabled={disabled}
                    selectedRetailers={selectedRetailers}
                    setSelectedRetailers={setSelectedRetailers}
                  />
                )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
